.hide {
    display: none;
}
.address {
    padding: 20px 0 !important;
}
.address_selector {
    display: flex;
    height: 408px;
    margin-top: 0 !important;
    margin-bottom: 15px !important;
    border-top: 1px solid #F7F7FC;
    border-bottom: 1px solid #E9E7EC;
}
.address1_group {
    width: 140px;
    background: #F7F7FC;
    overflow: auto;
    div {
            position: relative;
            padding: 20px;
            font-weight: 400;
            font-size: 16px;
            line-height: 100%;
            text-align: left;
            letter-spacing: -0.02em;
            height: 52px !important;
            &.toggled {
                font-weight: 600;
                background: #fff;
                &::after {
                    content: '';
                    position: absolute;
                    right: 10px;
                    top: 45%;
                    display: block;
                    width: 5px;
                    height: 10px;
                    background: url('/assets/icons/address_indicator.svg') no-repeat center / cover;
                }
            }
        }

    &::-webkit-scrollbar {
        display: none;
    }
}
.address2_group {
    flex: 1;
    padding: 0 20px;
    overflow: auto;
    ul {
        li {
            font-weight: 400;
            font-size: 16px;
            line-height: 100%;
            color : #9F9CA3;
            height: 52px !important;
            text-align: left;
        }
    }
    &::-webkit-scrollbar {
        display: none;
    }
}
.address_footer {
    padding: 0 20px;
}
.famousArea {
    display: flex;
    flex-wrap: wrap;
    .areaBtn {
        padding: 24px 0;
        border-radius: 4px;
        border: 1px solid var(--G2, #E9E7EC);
        color: var(--G4, #9F9CA3);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 16px */
        min-width: 45%;
        // flex: 1;
        letter-spacing: -0.32px;
        margin: 0 8px 8px 0;
        &.activeArea {
            border: 1px solid var(--B, #000);
            color: #000;
        }
    }
}